<i18n locale="th" lang="yaml" >
service.check.list_yes: "ใช่"
service.check.list_no: "ไม่ใช่"
service.check.list_work: "ได้"
service.check.list_not_work: "ไม่ได้"
service.check.list_no_value: "ไม่ได้ทดสอบ"
service.check.list_unspecified: "ไม่ระบุ"
</i18n>
<template>
	<div class="checklist-item checklist-row-border">
		<slot name="label">
			<label class="checklist-label">
				{{ label }}
			</label>
		</slot>
		<div v-if="value === undefined " class="checklist-no-value">
			{{$t('service.check.list_no_value')}}
		</div>
		<div v-else-if="typeof value === 'string' && value === ''" class="checklist-choice">
			{{$t('service.check.list_unspecified')}}
		</div>
		<div v-else-if="typeof value === 'string' && value !== ''" class="checklist-choice">
			{{ value }}
		</div>
		<div v-else class="checklist-choice">
			{{ others === true ? (value === true ? $t('service.check.list_yes') : $t('service.check.list_no')) : (value === true ? $t('service.check.list_work') : $t('service.check.list_not_work'))}}
		</div>
	</div>
</template>

<script>
export default {
	props: {
		value: {
			type: undefined,
			default: undefined,
		},
		label: {
			type: String,
			default: '',
		},
		others: {
			type: Boolean,
			default: false,
		}
	},
}
</script>
<style lang="less" scoped>
.checklist-item {
	display: flex;
	justify-content: left;
	border-bottom : 1px dashed @gray-5;
	&:last-child {
		border-bottom : none;
	}
	.checklist-label {
		display: flex;
		flex-basis: 80%;
		justify-content: left;
	}
	.checklist-no-value {
		display: flex;
		justify-content: center;
	}
	.checklist-choice {
		display: flex;
		flex-basis: 10%;
		justify-content: center;
	}
}
</style>
