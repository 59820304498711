<i18n locale="th" lang="yaml" src="@i18n/service/pre-delivery.th.yaml"></i18n>
<i18n locale="th" lang="yaml" src="@i18n/service/service-job.th.yaml"></i18n>
<i18n locale="th" lang="yaml" src="@i18n/service/onfield-service.th.yaml"></i18n>
<i18n locale="th" lang="yaml" >
action.can_repair : "ซ่อมนอกสถานที่ได้"
action.refer : "ส่งเข้าศูนย์"

onfield_pre_delivery.action.update.confirm.title: "กรุณายืนยันเพื่อดำเนินการต่อไป"
onfield_pre_delivery.action.update.confirm.message: "ต้องการดำเนินการไปยังขั้นตอนถัดไปหรือไม่ ?"
onfield_pre_delivery.page.title : "ใบทดสอบโดรน"
onfield_pre_delivery.page.description : "ขั้นตอนที่ 7/8"
onfield_pre_delivery.field.inspect : "ตรวจสภาพก่อนส่งคืน"
onfield_pre_delivery.form.success: "บันทึกใบทดสอบโดรนสำเร็จ"
onfield_pre_delivery.repair_fail_reason.to_be_refered: "ส่งเข้าศูนย์ซ่อม"
onfield_pre_delivery.repair_fail_reason.no_refer: "ลูกค้าไม่ประสงค์ซ่อม"
onfield_pre_delivery.repair_confirm.title: "บันทึกสถานะการซ่อม"
onfield_pre_delivery.repair_confirm.repair_status: "ซ่อมนอกสถานที่ได้หรือไม่"
onfield_pre_delivery.drone: "ข้อมูลโดรนก่อนส่งคืน"
onfield_pre_delivery.drone.none: "เป็นงานซ่อมเฉพาะอุปกรณ์เท่านั้น (ไม่ได้ทิ้งโดรนไว้)"
onfield_pre_delivery.inspect : "ข้อมูลการตรวจสอบก่อนส่งคืนโดรน"
onfield_pre_delivery.field.checklist : "การทดสอบการทำงานของโดรนก่อนส่งคืน"
onfield_pre_delivery.field.remark.placeholder : "เช่น สภาพของโดรนหรือ อุปกรณ์อื่น ๆ เพิ่มเติม หรือ สาเหตุที่ไม่ได้ตรวจสอบ เป็นต้น"

onfield_pre_delivery.action.update.no_form : "กรุณาสร้างใบทดสอบโดรน และบันทึกให้เรียบร้อยก่อนดำเนินการต่อ"
onfield_pre_delivery.action.update.confirm.success : "ดำเนินการเรียบร้อย"
</i18n>

<template>
	<div class="page page-padding page-fit-mobile">
		<my-page-header
			class="main-header"
			:title="$t('onfield_pre_delivery.page.title')"
			:description="$t('onfield_pre_delivery.page.description')"/>

		<div class="page-padding background">
			<template>
				<ErrorMessagePane :error="saveError" />
				<div class="pre_delivery-not-inspect">
					{{ $t('onfield_pre_delivery.field.inspect') }}:
					<a-checkbox v-model="preDeliveryValue.is_not_inspect">
						{{ $t('pre_delivery.field.is_not_inspect') }}
					</a-checkbox>
				</div>
				<hr />
			</template>
			<div class="mytab-section-title">
				{{ $t('service_repair.field.repair_result') }}
			</div>
			<div class="pre_delivery-subsection">
				<div class="pre_delivery-row">
					<label>{{ $t('onfield_pre_delivery.repair_confirm.repair_status')}} : </label>
					<div class="pre_delivery-input-block">
						{{canRepair ? $t('action.can_repair') : $t('action.refer') }}
					</div>
				</div>
			</div>
			<div class="mytab-section-title">
				{{$t('onfield_pre_delivery.drone')}}
			</div>
			<div v-if="service.is_device_only" class="pre_delivery-subsection">
				{{ $t('onfield_pre_delivery.drone.none') }}
			</div>
			<div v-else class="pre_delivery-subsection">
				<div class="pre_delivery-row">
					<label>{{ $t('pre_delivery.field.flight_time') }} * : </label>
					<div class="pre_delivery-input-block">
						<FlightTimeInput v-model="preDeliveryValue.flight_time_sec" />
					</div>
				</div>
			</div>
			<BlockPane :block="preDeliveryValue.is_not_inspect">
				<span slot="message">
					{{ $t('pre_delivery.field.is_not_inspect.message') }}
				</span>

				<div class="mytab-section-title">
					{{ $t('onfield_pre_delivery.inspect') }}
				</div>
				<div class="pre_delivery-subsection">
					<div class="pre_delivery-row">
						<label>{{ $t('pre_delivery.field.inspected_by') }} * : </label>
						<div class="pre_delivery-input-block">
							<UserCompanySelect
								v-model="preDeliveryValue.inspected_by_id"
								:company-id="service.company_id"
								disabled
								input-read-only
								required/>
						</div>
					</div>
					<template>
						<div class="pre_delivery-row">
							<label>{{ $t('pre_delivery.field.inspected_date') }} * : </label>
							<div class="pre_delivery-input-block">
								<MyDatePicker :value="inspectedDate" :read-only="true" input-read-only format="DD MMMM YYYY"/>
							</div>
						</div>
						<div class="pre_delivery-row">
							<label>{{ $t('pre_delivery.field.inspected_time') }} * : </label>
							<div class="pre_delivery-input-block">
								{{ inspectedTime }}
							</div>
						</div>
					</template>
				</div>

				<template v-if="!service.is_device_only">
					<div class="mytab-section-title">
						{{ $t('onfield_pre_delivery.field.checklist') }}
					</div>

					<div class="pre_delivery-subsection-full">
						<component
							:is="checklistComponent"
							ref="checklist"
							:can-update="true"
							:service="service"
							:checklist-type="preDelivery.checklist_type"/>
					</div>
				</template>
			</BlockPane>
			<ServiceOnfieldPreDeliveryForm
					ref="preDeliveryPrintModalRef"
					:modal-loading="modalLoading"
					:is-not-inspect="preDeliveryValue.is_not_inspect"
					:is-device-only="service.is_device_only"
					:pre-delivery="preDelivery"
					:service="service"
					@save="postDeliveryDroneDoc"/>
			<div class="create-pre_delivery-form">
				<a-button type="info" icon="form" @click="openPrintModal">
					{{ $t('pre_delivery.drone.test.result.button') }}
				</a-button>
			</div>
			<template>

				<div class="mytab-section-title">
					{{$t('pre_delivery.files')}}
				</div>
				<div class="pre_delivery-subsection files-upload">
					<div class="files-component">
						<ServiceFilesPanel
							ref="serviceFilesRef"
							:error="componentError"
							:service="service"
							:label="$t('pre_delivery.images.label')"
							:others-label="$t('pre_delivery.others.label')"
							:can-update="true"/>
					</div>
					<div class="files-component">
						<ServiceFilesPanel
							ref="serviceDocumentFilesRef"
							:error="componentError"
							:service="service"
							:label="$t('pre_delivery.document_images.label')"
							:others-label="$t('pre_delivery.document_files.label')"
							:can-update="true"/>
					</div>
				</div>
			</template>
			<div class="mytab-section-title">
				{{$t('pre_delivery.field.remark')}}
			</div>
			<div class="pre_delivery-subsection-full">
				<a-textarea
					v-model="preDeliveryValue.remark"
					style="max-width:800px;"
					:auto-size="{ minRows: 4,maxRows: 10 }"
					:read-only="false"
					:placeholder="$t('onfield_pre_delivery.field.remark.placeholder')"/>
			</div>
			<div class="page-action-right next-button">
				<a-button
					type="primary"
					icon="right-circle"
					@click="handleSave">
					{{$t('action.next.page')}}
				</a-button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import ServiceOnfieldPreDeliveryForm from '@components/service/ServiceOnfieldPreDeliveryForm.vue'
import PageMixin from '@mixins/PageMixin.vue'
import LoginInfoMixin from "@mixins/LoginInfoMixin.vue"
import ServiceObjectMixin from '@mixins/service/ServiceObjectMixin.vue'
import { transformToJsonProp, replaceDeepProperty } from '@utils/objectUtil'
import { Checkbox, Input } from 'ant-design-vue'
import MyDatePicker from '@components/input/MyDatePicker.vue'
import UserCompanySelect from '@components/user/UserCompanySelect.vue'
import BlockPane from '@components/common/BlockPane.vue'
import ImageName from '@components/common/ImageName.vue'
import FlightTimeInput from '@components/input/FlightTimeInput.vue'
import FlightTimeView from '@components/common/FlightTimeView.vue'
import InvalidChecklistType from '@components/service/InvalidChecklistType.vue'
import PreDeliveryTGD1XChecklist from '@components/service/tgd1x/PreDeliveryTGD1XChecklist.vue'
import ErrorMessagePane from '@components/common/ErrorMessagePane.vue'
import { scrollTopContent } from '@utils/formUtil'
import axios from 'axios'
import _isEqual from 'lodash/isEqual'
import ModelError from '@utils/errors/ModelError'
import ServiceFilesPanel from '@components/service/ServiceFilesPanel.vue'
import ApiError from '@utils/errors/ApiError'
import { hoursDisplayStr, timeRange } from '@utils/stringUtil'

function _defaultPreDelivery() {
	return {
		is_not_inspect: false,
		flight_time_sec: undefined,
		inspected_by_id: undefined,
		remark: undefined,
	}
}

export default {
	components: {
		'a-checkbox': Checkbox,
		'a-textarea': Input.TextArea,
		FlightTimeView,
		FlightTimeInput,
		InvalidChecklistType,
		PreDeliveryTGD1XChecklist,
		UserCompanySelect,
		MyDatePicker,
		BlockPane,
		ImageName,
		ServiceFilesPanel,
		ErrorMessagePane,
		ServiceOnfieldPreDeliveryForm,
	},
	mixins: [PageMixin, ServiceObjectMixin, LoginInfoMixin],
	data() {
		return {
			loading: false,
			modalLoading: false,
			preDelivery: {},
			service: {},
			serviceFiles: [],
			serviceDocumentFiles: [],

			preDeliveryValue: _defaultPreDelivery(),
			inspectedDate: undefined,
			inspectedTime: undefined,

			ignorePreventExit: false,
			oldFormData: {},

			saveError: undefined,

			canRepair: undefined,
			preDeliveryFormFileKey: '',
		}
	},
	computed: {
		...mapState('auth', ['currentCompany']),

		checklistComponent() {
			return PreDeliveryTGD1XChecklist
		},

		componentError() {
			return this.saveError instanceof ModelError ? this.saveError : undefined
		},
	},
	watch: {
		$route(newVal) {
			if (!this.preDelivery || newVal.params.id != this.preDelivery.id) {
				this.fetchPreDelivery()
			}
		},
	},
	mounted() {
		this.fetchData()
	},
	beforeMount() {
		window.addEventListener('beforeunload', this.preventExit)
	},
	beforeDestroy() {
		window.removeEventListener('beforeunload', this.preventExit)
	},
	methods: {
		getCheckResult(value) {
			if(value === undefined || value === null) {
				return '-'
			}

			return value === true? 'ผ่าน' : 'ไม่ผ่าน'
		},
		getGeneralChecklist(data) {
			return {
				items: [
					{ name: this.$t('tgd1x.delivery.checklist.general.nut_bolt_test'), result: this.getCheckResult(data.general.nut_bolt_test) },
					{ name: this.$t('tgd1x.delivery.checklist.general.nut_marking'), result: this.getCheckResult(data.general.nut_marking) },
					{ name: this.$t('tgd1x.delivery.checklist.general.holder_foam'), result: this.getCheckResult(data.general.holder_foam) },
					{ name: this.$t('tgd1x.delivery.checklist.general.z30_locking'), result: this.getCheckResult(data.general.z30_locking) },
					{ name: this.$t('tgd1x.delivery.checklist.general.z30_status'), result: this.getCheckResult(data.general.z30_status) },
					{ name: this.$t('tgd1x.delivery.checklist.general.spray_lock_status'), result: this.getCheckResult(data.general.spray_lock_status) },
					{ name: this.$t('tgd1x.delivery.checklist.general.propeller_position'), result: this.getCheckResult(data.general.propeller_position) },
					{ name: this.$t('tgd1x.delivery.checklist.general.propeller_status'), result: this.getCheckResult(data.general.propeller_status) },
					{ name: this.$t('tgd1x.delivery.checklist.general.battery_lock_status'), result: this.getCheckResult(data.general.battery_lock_status) },
					{ name: this.$t('tgd1x.delivery.checklist.general.cable_condition'), result: this.getCheckResult(data.general.cable_condition) },
					{ name: this.$t('tgd1x.delivery.checklist.general.pump_condition'), result: this.getCheckResult(data.general.pump_condition) },
				],
				nozzleType: data.general.nozzle_model,
				nozzleColor: data.general.nozzle_color,
				flowRate: data.general.nozzle_flow_rate,
			}
		},
		getFlightManualChecklist(data) {
			return {
				items: [
					{ name: this.$t('tgd1x.delivery.checklist.flight_manual.hgmc_connection'), result: this.getCheckResult(data.flight_manual.hgmc_connection) },
					{ name: this.$t('tgd1x.delivery.checklist.flight_manual.hgmc_sync_compass'), result: this.getCheckResult(data.flight_manual.hgmc_sync_compass) },
					{ name: this.$t('tgd1x.delivery.checklist.flight_manual.light_status_green'), result: this.getCheckResult(data.flight_manual.light_status_green) },
					{ name: this.$t('tgd1x.delivery.checklist.flight_manual.light_safety_red'), result: this.getCheckResult(data.flight_manual.light_safety_red) },
					{ name: this.$t('tgd1x.delivery.checklist.flight_manual.power_on_stop_normal'), result: this.getCheckResult(data.flight_manual.power_on_stop_normal) },
					{ name: this.$t('tgd1x.delivery.checklist.flight_manual.power_on_stop_emergency'), result: this.getCheckResult(data.flight_manual.power_on_stop_emergency) },
					{ name: this.$t('tgd1x.delivery.checklist.flight_manual.drone_stablity'), result: this.getCheckResult(data.flight_manual.drone_stablity) },
					{ name: this.$t('tgd1x.delivery.checklist.flight_manual.drone_controller'), result: this.getCheckResult(data.flight_manual.drone_controller) },
					{ name: this.$t('tgd1x.delivery.checklist.flight_manual.camera_switching'), result: this.getCheckResult(data.flight_manual.camera_switching) },
					{ name: this.$t('tgd1x.delivery.checklist.flight_manual.pump_during_flight_control'), result: this.getCheckResult(data.flight_manual.pump_during_flight_control) },
					{ name: this.$t('tgd1x.delivery.checklist.flight_manual.tank_sensor_status'), result: this.getCheckResult(data.flight_manual.tank_sensor_status) },
					{ name: this.$t('tgd1x.delivery.checklist.flight_manual.laser_altitude_control'), result: this.getCheckResult(data.flight_manual.laser_altitude_control) },
					{ name: this.$t('tgd1x.delivery.checklist.flight_manual.power_off_stop_normal'), result: this.getCheckResult(data.flight_manual.power_off_stop_normal) },
					{ name: this.$t('tgd1x.delivery.checklist.flight_manual.nut_bolt_test'), result: this.getCheckResult(data.flight_manual.nut_bolt_test) }
				],
				vibrationX: data.flight_manual.vibration_x,
				vibrationY: data.flight_manual.vibration_y,
				vibrationZ: data.flight_manual.vibration_z,
				laser1: data.flight_manual.laser_max_value_1,
				laser2: data.flight_manual.laser_max_value_2
			}
		},
		getflightAutoChecklist(data) {
			return {
				items: [
					{
						name: this.$t('tgd1x.delivery.checklist.flight_auto.flight_plan_transfer'),
						result1: data.flight_auto.test1.flight_plan_transfer === true ? '✓' : '-',
						result2: data.flight_auto.test2.flight_plan_transfer === true ? '✓' : '-'
					},
					{
						name: this.$t('tgd1x.delivery.checklist.flight_auto.drone_stablity'),
						result1: data.flight_auto.test1.drone_stablity === true ? '✓' : '-',
						result2: data.flight_auto.test2.drone_stablity === true ? '✓' : '-'
					},
					{
						name: this.$t('tgd1x.delivery.checklist.flight_auto.start_plan'),
						result1: data.flight_auto.test1.start_plan === true ? '✓' : '-',
						result2: data.flight_auto.test2.start_plan === true ? '✓' : '-'
					},
					{
						name: this.$t('tgd1x.delivery.checklist.flight_auto.plan_path_correct'),
						result1: data.flight_auto.test1.plan_path_correct === true ? '✓' : '-',
						result2: data.flight_auto.test2.plan_path_correct === true ? '✓' : '-'
					},
					{
						name: this.$t('tgd1x.delivery.checklist.flight_auto.parking_water_by_plan'),
						result1: data.flight_auto.test1.parking_water_by_plan === true ? '✓' : '-'
					},
					{
						name: this.$t('tgd1x.delivery.checklist.flight_auto.parking_water_during_flight'),
						result2: data.flight_auto.test2.parking_water_during_flight === true ? '✓' : '-'
					},
					{
						name: this.$t('tgd1x.delivery.checklist.flight_auto.stop_auto'),
						result1: data.flight_auto.test1.stop_auto === true ? '✓' : '-',
						result2: data.flight_auto.test2.stop_auto === true ? '✓' : '-'
					}
				]
			}
		},
		postDeliveryDroneDoc(data) {
			const formData = {
				renderData: {
					isNotInspect: this.preDeliveryValue.is_not_inspect,
					isDeviceOnly: this.service.is_device_only,
					needCheckList: !this.preDeliveryValue.is_not_inspect && !this.service.is_device_only,
					printDate: new Date().toLocaleDateString() + ': ' + new Date().toLocaleTimeString(),
					droneReceiveDateTime: data.droneReceiveDateTime ? data.droneReceiveDateTime : this.$t('common.unspecify'),
					inspectedDateTime: data.inspectedDateTime ? data.inspectedDateTime : this.$t('common.unspecify'),
					flightTime: hoursDisplayStr(
						this.preDeliveryValue.flight_time_sec,
						this.$t('common.hour.short'),
						this.$t('common.minute'),
						this.$t('common.second'),
						this.$t('common.unspecify')
					),
					companyData: data.companyData,
					serviceNumber: this.service.service_no,
					droneSerial: this.service.droneData.drone.serial_no,
					dronePixhawk: this.service.droneData.drone.pixhawk_serial_no,
					droneModelName: this.service.droneData.model.name,
					customerName: this.service.customer_name,
					customerPhone: this.service.customer_phone,
					customerProvince: this.service.customer_province,
					remark: this.preDeliveryValue.remark,
					signData: data.signData,
					isConsentChecked: data.isConsentChecked,
				},
				module: 'service'
			}

			if(this.$refs.checklist) {
				const checklistData = this.$refs.checklist.getData()
				formData.renderData.generalChecklist = this.getGeneralChecklist(checklistData)
				formData.renderData.flightManualChecklist = this.getFlightManualChecklist(checklistData)
				formData.renderData.flightAutoChecklist =  this.getflightAutoChecklist(checklistData)
				formData.renderData.deviceReturn = {
					isRcReturned: checklistData.returned_device.is_rc_returned,
					isTabletReturned: checklistData.returned_device.is_tablet_returned,
					isChargerReturned: checklistData.returned_device.is_charger_returned,
					isChargingPanelReturned: checklistData.returned_device.is_charging_panel_returned,
					battery1: checklistData.returned_device.battery1,
					battery2: checklistData.returned_device.battery2,
					other: checklistData.returned_device.other
				}
			}

			this.modalLoading = true
			axios.post('/api/services/' + this.service.id + '/onfield-pre-delivery-form-upload', formData).then((response) => {
					const uid = Math.floor(Math.random() * response.data.file.file_size)
					const customFile = {
						uid: uid,
						name: response.data.file.filename,
						file_key: response.data.file.file_key,
						size:  response.data.file.file_size,
						type: response.data.file.type,
						status: "done",
					}
					this.$refs.serviceDocumentFilesRef.$refs.fileUploader.addCustomFile(customFile)
					this.$message.success(this.$t('onfield_pre_delivery.form.success'))
					this.$refs.preDeliveryPrintModalRef.handleClose()
					this.preDeliveryFormFileKey = response.data.file.file_key
				})
				.catch((error) => {
					this.$message.error(ApiError.getDisplayErrorMessage(this, error))
				})
				.finally(() => {
					this.modalLoading = false
				})
		},
		openPrintModal() {
			const createDate = this.$dayjs(this.service.created_datetime)
			this.$refs.preDeliveryPrintModalRef.openModal({
				serviceId: this.service.id,
				flightTime: this.preDeliveryValue.flight_time_sec,
				inspectedDateTime: this.inspectedDate + ' ' + this.inspectedTime + ':00',
				serviceNumber: this.service.service_no,
				droneSerial: this.service.droneData.drone.serial_no,
				dronePixhawk: this.service.droneData.drone.pixhawk_serial_no,
				droneModelName: this.service.droneData.model.name,
				customerName: this.service.customer_name,
				customerPhone: this.service.customer_phone,
				customerProvince: this.service.customer_province,
				remark: this.preDeliveryValue.remark,
				technician: this.service.technicianData ? this.service.technicianData.user.full_name : '',
				droneReceiveDateTime: createDate.format('YYYY-MM-DD') + ' เวลา ' + createDate.format('HH:mm'),
			})

			const checklistC = this.$refs.checklist
			if (checklistC && typeof checklistC.getData === 'function') {
				this.$refs.preDeliveryPrintModalRef.setData(checklistC.getData())
			}
		},
		updateRepairResult() {
			this.$confirm({
				title: this.$t('onfield_pre_delivery.repair_confirm.title'),
				content: this.$t('onfield_pre_delivery.repair_confirm.repair_status'),
				okText: this.$t('action.can_repair'),
				cancelText : this.$t('action.refer') ,
				maskClosable: true,
				onOk: () => {
					this.canRepair = true
				},
				onCancel: () => {
					this.canRepair = false
				}
			})
		},
		async fetchData() {
			const serviceId = this.$route.params.id
			if (!serviceId) {
					return
			}
			this.loading = true
			try {
				const responseService = await axios.get(`/api/services/${serviceId}`)
				this.service = {...responseService.data.data.service}
				const responsePreDelivery = await axios.get(`/api/services/${serviceId}/pre-delivery`)
				this.preDelivery = {...responsePreDelivery.data.data.pre_delivery}
				this.serviceFiles = [...responsePreDelivery.data.data.service_files]
				this.serviceDocumentFiles = [...responsePreDelivery.data.data.service_document_files]
				this.updateRepairResult()
				this.initPreDelivery()
			} catch (error) {
				this.fetchError(error)
			} finally {
				this.loading = false
			}
    },
		initPreDelivery() {
			this.saveError = undefined
			this.inspectedDate = this.$dayjs().format('YYYY-MM-DD')
			this.inspectedTime = this.$dayjs().format('HH:mm')

			this.preDeliveryValue = _defaultPreDelivery()
			if (this.preDelivery) {
				replaceDeepProperty(this.preDeliveryValue, this.preDelivery)
			}
			this.preDeliveryValue.inspected_by_id = this.service.open_by_id

			this.$nextTick(() => {
				const checklistComp = this.$refs.checklist
				if (checklistComp && typeof checklistComp.setData === 'function') {
					if (this.preDelivery.checklistData) {
						checklistComp.setData(this.preDelivery.checklistData)
					} else {
						checklistComp.setData({})
					}
				}
				if (this.$refs.serviceFilesRef) {
					this.$refs.serviceFilesRef.setData(this.serviceFiles)
				}
				if (this.$refs.serviceDocumentFilesRef) {
					this.$refs.serviceDocumentFilesRef.setData(this.serviceDocumentFiles)
				}
				this.$nextTick(() => {
					this.oldFormData = this.dumpFormData()
				})
			})
		},
		dumpFormData() {
			const formData = {
				id: this.preDelivery.id,
				...this.preDeliveryValue,
				service_files: [],
			}
			if (formData.inspected_by_id === undefined) {
				formData.inspected_by_id = null
			}
			if (this.$notEmpty(this.inspectedDate) && this.$notEmpty(this.inspectedTime)) {
				formData.inspected_datetime = this.inspectedDate + ' ' + this.inspectedTime + ':00'
			} else {
				if (this.$notEmpty(this.inspectedDate)) {
					formData.inspected_datetime = this.inspectedDate + ' 10:00:00'
				} else {
					formData.inspected_datetime = null
				}
			}
			const checklistC = this.$refs.checklist
			if (checklistC && typeof checklistC.getData === 'function') {
				formData.checklistData = checklistC.getData()
			}
			if (this.$refs.serviceFilesRef) {
				formData.service_files.push({upload_type: 'pre-delivery', files: this.$refs.serviceFilesRef.getData()})
			}
			if (this.$refs.serviceDocumentFilesRef) {
				formData.service_files.push({upload_type: 'pre-delivery-doc', files: this.$refs.serviceDocumentFilesRef.getData()})
			}
			return formData
		},
		getRepairFailReason() {
			return !this.canRepair ? this.$t('onfield_pre_delivery.repair_fail_reason.to_be_refered') : ''
		},
		handleSave() {
			const preFormData = this.dumpFormData()
			if (!this.isDataChange(preFormData)) {
				this.$message.info(this.$t('pre_delivery.action.update.no_change'))
				return
			}

			const serviceFiles = preFormData.service_files
			delete preFormData.service_files
			const docForm = serviceFiles[1].files.find(file => file.file_key === this.preDeliveryFormFileKey)
			if (!docForm) {
				this.$message.error(this.$t('onfield_pre_delivery.action.update.no_form'))
				return
			}

			this.$confirm({
				title: this.$t('onfield_pre_delivery.action.update.confirm.title'),
				content: this.$t('onfield_pre_delivery.action.update.confirm.message'),
				okText: this.$t('common.ok'),
				cancelText : this.$t('common.cancel'),
				maskClosable: true,
				onOk: () => {
					this.showPageLoading(true)
					const repairData = {
						job_repair: {
							repair_result: this.canRepair? 'success':'fail',
							repair_result_reason: this.getRepairFailReason()
						}
					}

					axios.put('/api/services/' + this.service.id + '/jobs', repairData)
						.then(response => {
							return	axios.post('/api/services/' + this.service.id + '/update-status/pre_delivery')
						})
						.then((response) => {
							// transform formData
							transformToJsonProp(preFormData, 'checklistData', 'checklist_json')

							const formData = {
								pre_delivery: preFormData,
								service_files: serviceFiles,
							}
							return axios.put('/api/services/' + this.service.id + '/pre-delivery', formData)
						})
						.then((response) => {
							return	axios.post('/api/services/' + this.service.id + '/update-status/wait_delivery')
						})
						.then((response) => {
							const formData = {
								return: {
									return_type: this.canRepair ?  'customer': 'refer',
									delivery_method: 'self_shipping',
									return_date: this.inspectedDate,
									return_time_range: timeRange(this.$dayjs().hour()),
									return_by_id: this.currentUser.id,
									remark: null,
									delivery_json: '{"shipping_by":"' + this.currentUser.full_name + '","receive_date":"' + this.inspectedDate + '"}'
								},
							}

							if (!this.canRepair)
								formData.refer = { to_company_id: this.currentCompany.id }

							return	axios.put('/api/services/' + this.service.id + '/return', formData)
						})
						.then((response) => {
							return axios.post('/api/services/' + this.service.id + '/update-onfield-state', { state: 'return' })
						})
						.then((response) => {
							this.ignorePreventExit = true
							this.$message.success(this.$t('onfield_pre_delivery.action.update.confirm.success'))
							this.$router.push({ name: 'onfield/return', params:{ id:this.service.id } })
						})
						.catch((error) => {
							this.saveError = error
							scrollTopContent()
						})
						.finally(() => {
							this.hidePageLoading()
						})
				},
			})
		},
		preventExit(event) {
			if (this.checkPreventExit()) {
				event.preventDefault()
				event.returnValue = ''
			}
		},
		isDataChange(formData) {
			const change = !_isEqual(this.oldFormData, formData)
			return change
		},
		checkPreventExit(formData = null) {
			if (this.ignorePreventExit) return false
			if (!formData) {
				formData = this.dumpFormData()
			}
			return this.isDataChange(formData)
		},
	},
	beforeRouteLeave(routeTo, routeFrom, next) {
		const formData = this.dumpFormData()
		if (this.checkPreventExit(formData)) {
			this.$confirm({
				title: this.$t('pre_delivery.action.ask_leave.title'),
				content: this.$t('pre_delivery.action.ask_leave.message'),
				okText: this.$t('common.confirm'),
				maskClosable: true,
				onOk: () => {
					next()
				},
			})
		} else {
			next()
		}
	},
}
</script>

<style lang="less" scoped>
.create-pre_delivery-form {
	margin: 0 0 10px 0;
}
.main-header {
	text-align: center;
}
.background {
	background : white;

	.mobile & {
		padding : 12px 16px;
	}
}
.next-button {
	margin: 10px 0 0 0;
}
.pre_delivery-subsection {
	margin-left: 64px;
	margin-bottom: 32px;
	.mobile & {
		margin-left: 16px;
	}
}
.pre_delivery-subsection-full {
	margin-bottom: 32px;
}
.pre_delivery-row {
	margin-bottom: 12px;
	label {
		color: @info-color;
		.mobile & {
			margin-bottom: 4px;
		}
	}
}
.pre_delivery-input-block {
	display: inline-block;
	margin-right: 8px;
	.mobile & {
		display: block;
		margin-bottom: 4px;
		margin-right: 0;
	}
}
.files-component {
	margin-right: 2rem;
}
.files-upload {
	display: flex;
	flex-direction: row;
	margin-right: 2rem;
}
.page-action-right {
	float: right;
	margin-bottom : 8px;
}
</style>
