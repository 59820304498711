
<i18n locale="th" lang="yaml" src="@i18n/service/pre-delivery.th.yaml"></i18n>

<template>
	<div :class="containerCssClasses">
		<div class="pre_delivery-checklist-block">
			<div class="checklist-row checklist-row-title checklist-row-border">
				<label class="title">
					<span class="topic_no">1</span> {{$t('tgd1x.delivery.checklist.general.label')}}
				</label>
				<div v-trim class="checklist-check">
					<label class="ant-radio-wrapper">{{$t('tgd1x.delivery.checklist.pass')}}</label>
					<label class="ant-radio-wrapper">{{$t('tgd1x.delivery.checklist.fail')}}</label>
				</div>
			</div>

			<ServiceChecklistRow v-for="prop in generalCheckboxProps" :key="prop"
				v-model="checklistData.general[prop]"
				class="checklist-row-border"
				:can-update="canUpdate"
				:label="$t('tgd1x.delivery.checklist.general.' + prop)"/>

			<div class="checklist-row checklist-row-border">
				<div>
					<label>{{$t('tgd1x.delivery.checklist.general.nozzle_model')}}</label>
					<a-input
						v-if="canUpdate"
						v-model="checklistData.general.nozzle_model" class="pre_delivery-general-input"
						size="small" type="text"/>
					<label v-else class="text-info">
						: {{checklistData.general.nozzle_model | emptyCheck}}
					</label>

					<label>{{$t('tgd1x.delivery.checklist.general.nozzle_color')}}</label>
					<a-input
						v-if="canUpdate"
						v-model="checklistData.general.nozzle_color" class="pre_delivery-general-input"
						size="small" type="text"/>
					<label v-else class="text-info">
						: {{checklistData.general.nozzle_color | emptyCheck}}
					</label>

					<label>{{$t('tgd1x.delivery.checklist.general.nozzle_flow_rate')}}</label>
					<a-input
						v-if="canUpdate"
						v-model="checklistData.general.nozzle_flow_rate" class="pre_delivery-general-input"
						size="small" type="text"
						style="width:50px"/>
					<label v-else class="text-info">
						: {{checklistData.general.nozzle_flow_rate | emptyCheck}}
					</label>
					<label>{{$t('tgd1x.delivery.checklist.general.nozzle_flow_rate.suffix')}}</label>
				</div>
			</div>

			<div v-if="canUpdate" class="checklist-row checklist-row-footer checklist-row-border">
				<div>
					<a-checkbox
						:checked="generalCheckAll"
						@change="handleGeneralCheckAll">
						{{$t('tgd1x.delivery.checklist.pass_all')}}
					</a-checkbox>
				</div>
			</div>
		</div>

		<div class="pre_delivery-checklist-block">
			<div class="checklist-row checklist-row-title checklist-row-border">
				<label class="title">
					<span class="topic_no">2</span> {{$t('tgd1x.delivery.checklist.flight_manual.label')}}
				</label>
				<div v-trim class="checklist-check">
					<label class="ant-radio-wrapper">{{$t('tgd1x.delivery.checklist.pass')}}</label>
					<label class="ant-radio-wrapper">{{$t('tgd1x.delivery.checklist.fail')}}</label>
				</div>
			</div>

			<ServiceChecklistRow v-for="prop in flightManualCheckboxProps" :key="prop"
				v-model="checklistData.flight_manual[prop]"
				class="checklist-row-border"
				:can-update="canUpdate"
				:label="$t('tgd1x.delivery.checklist.flight_manual.' + prop)"/>
			<div class="checklist-row checklist-row-border">
				<label>{{$t('tgd1x.delivery.checklist.flight_manual.vibration')}}</label>
				<div class="checklist-check">
					<label>{{$t('tgd1x.delivery.checklist.flight_manual.vibration_x')}}</label>
					<a-input
						v-if="canUpdate"
						v-model="checklistData.flight_manual.vibration_x" class="pre_delivery-flight-input"
						size="small" type="text"/>
					<label v-else class="text-info">
						: {{checklistData.flight_manual.vibration_x | emptyCheck}}
					</label>
					<label>{{$t('tgd1x.delivery.checklist.flight_manual.vibration_y')}}</label>
					<a-input
						v-if="canUpdate"
						v-model="checklistData.flight_manual.vibration_y" class="pre_delivery-flight-input"
						size="small" type="text"/>
					<label v-else class="text-info">
						: {{checklistData.flight_manual.vibration_y | emptyCheck}}
					</label>
					<label>{{$t('tgd1x.delivery.checklist.flight_manual.vibration_z')}}</label>
					<a-input
						v-if="canUpdate"
						v-model="checklistData.flight_manual.vibration_z" class="pre_delivery-flight-input"
						size="small" type="text"/>
					<label v-else class="text-info">
						: {{checklistData.flight_manual.vibration_z | emptyCheck}}
					</label>
				</div>
			</div>
			<div class="checklist-row checklist-row-border">
				<label>{{$t('tgd1x.delivery.checklist.flight_manual.laser')}}</label>
				<div class="checklist-check">
					<label>{{$t('tgd1x.delivery.checklist.flight_manual.laser_max_value_1')}}</label>
					<a-input
						v-if="canUpdate"
						v-model="checklistData.flight_manual.laser_max_value_1" class="pre_delivery-flight-input"
						size="small" type="text"/>
					<label v-else class="text-info">
						: {{checklistData.flight_manual.laser_max_value_1 | emptyCheck}}
					</label>
					<label>{{$t('tgd1x.delivery.checklist.flight_manual.laser_max_value_2')}}</label>
					<a-input
						v-if="canUpdate"
						v-model="checklistData.flight_manual.laser_max_value_2" class="pre_delivery-flight-input"
						size="small" type="text"/>
					<label v-else class="text-info">
						: {{checklistData.flight_manual.laser_max_value_2 | emptyCheck}}
					</label>
				</div>
			</div>
			<ServiceChecklistRow
				v-model="checklistData.flight_manual.power_off_stop_normal"
				class="checklist-row-border"
				:can-update="canUpdate"
				:label="$t('tgd1x.delivery.checklist.flight_manual.power_off_stop_normal')"/>
			<ServiceChecklistRow
				v-model="checklistData.flight_manual.nut_bolt_test"
				class="checklist-row-border"
				:can-update="canUpdate"
				:label="$t('tgd1x.delivery.checklist.flight_manual.nut_bolt_test')"/>

			<div v-if="canUpdate" class="checklist-row checklist-row-footer checklist-row-border">
				<div>
					<a-checkbox
						:checked="flightManualCheckAll"
						@change="handleFlightManualCheckAll">
						{{$t('tgd1x.delivery.checklist.pass_all')}}
					</a-checkbox>
				</div>
			</div>
		</div>

		<div class="pre_delivery-checklist-block">
			<div class="checklist-row checklist-row-title checklist-row-border">
				<label class="title">
					<span class="topic_no">3</span> {{$t('tgd1x.delivery.checklist.flight_auto.label')}}
				</label>
				<div class="checklist-check">
					<label class="ant-radio-wrapper">{{$t('tgd1x.delivery.checklist.flight_auto.test1')}}</label>
					<label class="ant-radio-wrapper">{{$t('tgd1x.delivery.checklist.flight_auto.test2')}}</label>
				</div>
			</div>

			<div class="checklist-row checklist-row-border">
				<label>
					{{$t('tgd1x.delivery.checklist.flight_auto.flight_plan_transfer')}}
				</label>
				<div class="checklist-check">
					<a-checkbox
						:checked="checklistData.flight_auto.test1.flight_plan_transfer"
						@change="handleChangeFlightAuto($event,'test1.flight_plan_transfer')"/>
					<a-checkbox
						:checked="checklistData.flight_auto.test2.flight_plan_transfer"
						@change="handleChangeFlightAuto($event,'test2.flight_plan_transfer')"/>
				</div>
			</div>
			<div class="checklist-row checklist-row-border">
				<label>
					{{$t('tgd1x.delivery.checklist.flight_auto.drone_stablity')}}
				</label>
				<div class="checklist-check">
					<a-checkbox
						:checked="checklistData.flight_auto.test1.drone_stablity"
						@change="handleChangeFlightAuto($event,'test1.drone_stablity')"/>
					<a-checkbox
						:checked="checklistData.flight_auto.test2.drone_stablity"
						@change="handleChangeFlightAuto($event,'test2.drone_stablity')"/>
				</div>
			</div>
			<div class="checklist-row checklist-row-border">
				<label>
					{{$t('tgd1x.delivery.checklist.flight_auto.start_plan')}}
				</label>
				<div class="checklist-check">
					<a-checkbox
						:checked="checklistData.flight_auto.test1.start_plan"
						@change="handleChangeFlightAuto($event,'test1.start_plan')"/>
					<a-checkbox
						:checked="checklistData.flight_auto.test2.start_plan"
						@change="handleChangeFlightAuto($event,'test2.start_plan')"/>
				</div>
			</div>
			<div class="checklist-row checklist-row-border">
				<label>
					{{$t('tgd1x.delivery.checklist.flight_auto.plan_path_correct')}}
				</label>
				<div class="checklist-check">
					<a-checkbox
						:checked="checklistData.flight_auto.test1.plan_path_correct"
						@change="handleChangeFlightAuto($event,'test1.plan_path_correct')"/>
					<a-checkbox
						:checked="checklistData.flight_auto.test2.plan_path_correct"
						@change="handleChangeFlightAuto($event,'test2.plan_path_correct')"/>
				</div>
			</div>
			<div class="checklist-row checklist-row-border">
				<label>
					{{$t('tgd1x.delivery.checklist.flight_auto.parking_water_by_plan')}}
				</label>
				<div class="checklist-check">
					<a-checkbox
						:checked="checklistData.flight_auto.test1.parking_water_by_plan"
						@change="handleChangeFlightAuto($event,'test1.parking_water_by_plan')"/>
					<label class="ant-checkbox-wrapper"> </label>
				</div>
			</div>
			<div class="checklist-row checklist-row-border">
				<label>
					{{$t('tgd1x.delivery.checklist.flight_auto.parking_water_during_flight')}}
				</label>
				<div class="checklist-check">
					<label class="ant-checkbox-wrapper"> </label>
					<a-checkbox
						:checked="checklistData.flight_auto.test2.parking_water_during_flight"
						@change="handleChangeFlightAuto($event,'test2.parking_water_during_flight')"/>
				</div>
			</div>
			<div class="checklist-row checklist-row-border">
				<label>
					{{$t('tgd1x.delivery.checklist.flight_auto.stop_auto')}}
				</label>
				<div class="checklist-check">
					<a-checkbox
						:checked="checklistData.flight_auto.test1.stop_auto"
						@change="handleChangeFlightAuto($event,'test1.stop_auto')"/>
					<a-checkbox
						:checked="checklistData.flight_auto.test2.stop_auto"
						@change="handleChangeFlightAuto($event,'test2.stop_auto')"/>
				</div>
			</div>

			<div v-if="canUpdate" class="checklist-row checklist-row-footer checklist-row-border">
				<div>
					<a-checkbox
						:checked="flightAutoCheckAll"
						@change="handleFlightAutoCheckAll">
						{{$t('tgd1x.delivery.checklist.pass_all')}}
					</a-checkbox>
				</div>
			</div>

		</div>

		<div class="pre_delivery-checklist-block">
			<div class="checklist-row checklist-row-title checklist-row-border">
				<label class="title">
					<span class="topic_no">4</span> {{$t('tgd1x.delivery.checklist.returned_device.label')}}
				</label>
			</div>
			<div class="checklist-row checklist-row-border">
				<label>
					{{$t('tgd1x.delivery.checklist.returned_device.rc')}}
				</label>
				<div class="checklist-check">
					<a-checkbox v-model="checklistData.returned_device.is_rc_returned"/>
				</div>
			</div>
			<div class="checklist-row checklist-row-border">
				<label>
					{{$t('tgd1x.delivery.checklist.returned_device.tablet')}}
				</label>
				<div class="checklist-check">
					<a-checkbox v-model="checklistData.returned_device.is_tablet_returned"/>
				</div>
			</div>
			<div class="checklist-row checklist-row-border">
				<label>
					{{$t('tgd1x.delivery.checklist.returned_device.charger')}}
				</label>
				<div class="checklist-check">
					<a-checkbox v-model="checklistData.returned_device.is_charger_returned"/>
				</div>
			</div>
			<div class="checklist-row checklist-row-border">
				<label>
					{{$t('tgd1x.delivery.checklist.returned_device.charging_panel')}}
				</label>
				<div class="checklist-check">
					<a-checkbox v-model="checklistData.returned_device.is_charging_panel_returned"/>
				</div>
			</div>
			<div class="checklist-row checklist-row-border">
				<div>
					<label>{{$t('tgd1x.delivery.checklist.returned_device.battery1')}}</label>
					<a-input
						v-if="canUpdate"
						v-model="checklistData.returned_device.battery1" class="pre_delivery-return-input"
						size="small" type="text"/>
				</div>
			</div>

			<div class="checklist-row checklist-row-border">
				<div>
					<label>{{$t('tgd1x.delivery.checklist.returned_device.battery2')}}</label>
					<a-input
						v-if="canUpdate"
						v-model="checklistData.returned_device.battery2" class="pre_delivery-return-input"
						size="small" type="text"/>
				</div>
			</div>

			<div class="checklist-row checklist-row-border">
				<div>
					<label>{{$t('tgd1x.delivery.checklist.returned_device.other')}}</label>
					<a-input
						v-if="canUpdate"
						v-model="checklistData.returned_device.other" class="pre_delivery-return-input"
						size="small" type="text"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {Checkbox} from "ant-design-vue"
import ServiceChecklistRow from "../ServiceChecklistRow.vue"
import _set from "lodash/set"
import _get from "lodash/get"
import _indexOf from "lodash/indexOf"
import {replaceDeepProperty,copyDeep} from "@utils/objectUtil"
function _defaultChecklistData() {
	return {
		general : {
			nut_bolt_test : undefined,
			nut_marking : undefined,
			holder_foam : undefined,
			z30_locking : undefined,
			z30_status : undefined,
			spray_lock_status : undefined,
			propeller_position : undefined,
			propeller_status : undefined,
			battery_lock_status : undefined,
			cable_condition : undefined,
			pump_condition : undefined,
			nozzle_model : '',
			nozzle_color : '',
			nozzle_flow_rate : '',
		} ,
		flight_manual : {
			hgmc_connection : undefined ,
			hgmc_sync_compass : undefined,
			light_status_green : undefined,
			light_safety_red : undefined,
			power_on_stop_normal : undefined,
			power_on_stop_emergency : undefined,
			drone_stablity : undefined,
			drone_controller : undefined,
			camera_switching : undefined,
			pump_during_flight_control : undefined,
			tank_sensor_status : undefined,
			laser_altitude_control : undefined,
			vibration_x : '',
			vibration_y : '',
			vibration_z : '',
			laser_max_value_1 : '',
			laser_max_value_2 : '' ,
			power_off_stop_normal : undefined,
			nut_bolt_test : undefined,
		} ,
		flight_auto : {
			test1 : {
				flight_plan_transfer : undefined,
				drone_stablity : undefined,
				start_plan : undefined,
				plan_path_correct : undefined,
				parking_water_by_plan : undefined,
				stop_auto : undefined,
			} ,
			test2 : {
				flight_plan_transfer : undefined,
				drone_stablity : undefined,
				start_plan : undefined,
				plan_path_correct : undefined,
				parking_water_during_flight : undefined,
				stop_auto : undefined,
			}
		},
		returned_device: {
			is_rc_returned: false,
			is_tablet_returned: false,
			is_charger_returned: false,
			is_charging_panel_returned: false,
			battery1: undefined,
			battery2: undefined,
			other: undefined
		}
	}
}

export default {
	components : {
		ServiceChecklistRow ,
		"a-checkbox" : Checkbox,
	} ,
	props : {
		service : {
			type : null,
			default : () => []
		} ,
		canUpdate : {
			type : Boolean,
			default : false,
		}
	} ,
	data() {
		return {
			checklistData : _defaultChecklistData() ,
		}
	} ,
	computed : {
		generalCheckAll() {
			let all = true
			for(const prop of this.generalCheckboxProps) {
				all = all && _get(this.checklistData,"general."+prop)
			}
			return all
		} ,
		flightManualCheckAll() {
			let all = true
			for(const prop of this.flightManualCheckboxProps) {
				all = all && _get(this.checklistData,"flight_manual."+prop)
			}
			all = all && _get(this.checklistData,"flight_manual.power_off_stop_normal")
			all = all && _get(this.checklistData,"flight_manual.nut_bolt_test")
			return all
		} ,
		flightAutoCheckAll() {
			let all = true
			for(const prop in this.checklistData.flight_auto.test1) {
					all = all && _get(this.checklistData,"flight_auto.test1."+prop)
			}
			for(const prop in this.checklistData.flight_auto.test2) {
					all = all && _get(this.checklistData,"flight_auto.test2."+prop)
			}
			return all
		} ,
		containerCssClasses() {
			return [
				'pre_delivery-checklist' ,
				{
					'checklist-readonly' : !this.canUpdate
				}
			]
		} ,
		checkOptions() {
			return [
				{'value' : true} ,
				{'value' : false}
			]
		} ,
		generalCheckboxProps() {
			const excludedList = ['nozzle_model','nozzle_color','nozzle_flow_rate']
			return Object.keys(this.checklistData.general)
				.filter((v) => _indexOf(excludedList,v) < 0)
		} ,
		flightManualCheckboxProps() {
			const excludedList = ['vibration_x','vibration_y','vibration_z','laser_max_value_1','laser_max_value_2','power_off_stop_normal','nut_bolt_test']
			return Object.keys(this.checklistData.flight_manual)
				.filter((v) => _indexOf(excludedList,v) < 0)
		}
	} ,
	methods : {
		setData(data) {
			this.checklistData = _defaultChecklistData()
			if (data) {
				replaceDeepProperty(this.checklistData,data)
			}
		} ,
		getData() {
			return copyDeep(this.checklistData)
		} ,
		handleClearChecklist(attribute) {
			_set(this.checklistData,attribute,undefined)
		} ,
		handleChangeFlightAuto(event,attribute) {
			if (this.canUpdate)
				_set(this.checklistData,"flight_auto."+attribute,event.target.checked)
		} ,
		handleGeneralCheckAll(event) {
			let checked = event.target.checked
			if (!checked)
				checked = undefined
			for(const prop of this.generalCheckboxProps) {
				_set(this.checklistData,"general."+prop,checked)
			}
		} ,
		handleFlightManualCheckAll(event) {
			let checked = event.target.checked
			if (!checked)
				checked = undefined
			for(const prop of this.flightManualCheckboxProps) {
				_set(this.checklistData,"flight_manual."+prop,checked)
			}
			_set(this.checklistData,"flight_manual.power_off_stop_normal",checked)
			_set(this.checklistData,"flight_manual.nut_bolt_test",checked)
		} ,
		handleFlightAutoCheckAll(event) {
			let checked = event.target.checked
			if (!checked)
				checked = undefined
			for(const prop in this.checklistData.flight_auto.test1) {
					_set(this.checklistData,"flight_auto.test1."+prop,checked)
			}
			for(const prop in this.checklistData.flight_auto.test2) {
					_set(this.checklistData,"flight_auto.test2."+prop,checked)
			}
		}
	}
}
</script>

<style lang="less" scoped>
.pre_delivery-checklist {
	display : flex;
	align-items: stretch;
	flex-wrap: wrap;
	.pre_delivery-general-input {
		width : 60px;
		margin-right : 4px;
		font-size : 0.9em;
	}
	.pre_delivery-flight-input {
		width : 40px;
		margin-right : 4px;
		font-size : 0.9em;
	}
	.pre_delivery-return-input {
		width : 180px;
		margin-right : 4px;
		font-size : 0.9em;
	}
	.pre_delivery-checklist-block {
		flex-grow: 1;
		flex-basis: 325px;
		min-width : 325px;
		max-width: 400px;
		margin-bottom : 24px;
		padding-right : 16px;

		.mobile  & {
			max-width: 100%;
			padding-right : 0;
			flex-basis: 275px;
			min-width : 275px;
		}

	}
}
</style>
