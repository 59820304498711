<i18n locale="th" lang="yaml" >
select.user.placeholder.default : "เลือกผู้ใช้งาน"
</i18n>

<template>
		<div>
			<template v-if="disabled">
				<div v-if="userSelected" class="mycard-select-card">
					<span class="card-title">{{getNameDisplay(userSelected.user)}}</span>
					({{$tenum('user_role',`${userSelected.user.user_role}`)}})
				</div>
			</template>
			<template v-else>
				<a-select
					class="myselect-users"
					:loading="searchLoading"
					:placeholder="displayPlaceholder"
					:value="value ? value : undefined"
					:allow-clear="allowClear"
					:get-popup-container="getPopupContainer"
					@change="handleChange">
					<a-select-option v-for="userData in userDataList" :key="userData.id" :value="userData.id">
						<div class="mycard-select-card">
							<span class="card-title">{{getNameDisplay(userData.user)}}</span>
							({{$tenum('user_role',`${userData.user.user_role}`)}})
						</div>
					</a-select-option>
				</a-select>
			</template>
		</div>
</template>

<script>
import {Select} from "ant-design-vue"
import { mapState} from "vuex"
import {isStringEmpty} from "@utils/stringUtil"
import ApiError from '@utils/errors/ApiError'
import PopupMixin from "@mixins/PopupMixin.vue"
import LoginInfoMixin from "@mixins/LoginInfoMixin.vue"
import axios from "axios"
import { fulfillUser ,emptyUser } from '@utils/objectUtil'
export default {
	components : {
		"a-select" : Select,
		"a-select-option" : Select.Option,
	} ,
	mixins : [PopupMixin,LoginInfoMixin] ,
	model : {
		prop : 'value',
		event : 'change',
	} ,
	props : {
		value : {
			type : null,
			default : undefined
		} ,
		placeholder : {
			type : String,
			default : null
		} ,
		allowClear : {
			type : Boolean,
			default : false,
		} ,
		disabled : {
			type : Boolean,
			default : false,
		} ,
		companyId : {
			type : null,
			default : undefined,
		}
	} ,
	data() {
		return {
			searchLoading : false ,
			userDataList : [],
		}
	} ,
	computed : {
		...mapState('user',['userDatas']) ,
		displayPlaceholder() {
			return isStringEmpty(this.placeholder) ? this.$t('select.user.placeholder.default') : this.placeholder
		} ,
		userSelected(){
			return this.userDataList.find(userData => userData.id === this.value)
		},
	} ,
	watch : {
		companyId() {
			this.fetchUsers()
		}
	} ,
	mounted() {
		this.fetchUsers()
	} ,

	methods : {
		getNameDisplay(user){
			const technician = 3
			if (user.user_role === technician)
				return user.full_name
			else
				return user.username
		} ,
		fetchUsers() {
			if (!this.companyId || this.companyId == this.currentCompany.id) {
				this.userDataList = this.userDatas
			} else {
				this.searchLoading = true
				this.userDataList = []
				const searchParams = {
					search : {
						page_size : 0 ,
					} ,
					filter : {
						status : 'active' ,
						mode : 'company',
						company_id : this.companyId
					}
				}
				axios.get("/api/users/search",{params : searchParams}).then((response)=> {
					const bucket = response.data.data.bucket
					this.userDataList = response.data.data.users.map((user)=> {
						return fulfillUser(user,bucket)
					})
				}).catch((error)=>{
					this.$message.error(ApiError.getDisplayErrorMessage(this,error));
				}).finally(()=>{
					this.searchLoading = false
				})
			}
		} ,
		handleChange(value) {
			this.$emit('change',value);
			const userData = this.userDataList.find((u)=> u.id == value)
			if (!userData) {
				this.$emit('changeUserData',emptyUser())
			} else {
				this.$emit('changeUserData',userData)
			}
		}
	}
}
</script>

<style lang="less" scoped>
.myselect-users {
	width : 250px;
}
</style>
