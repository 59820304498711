<i18n locale="th" lang="yaml" >
checklist.clear.title : "ล้างค่าที่เลือกไว้"
</i18n>

<template>
	<div class="checklist-row">
		<slot name="label">
			<label>
				{{label}}
			</label>
		</slot>

		<div class="checklist-check" >
			<a-radio-group
				:value="value"
				:name="radioName"
				@change="handleChange">
				<a-radio :value="true"	:tab-index="canUpdate ? 0 : -1"/>
				<a-radio :value="false"	:tab-index="canUpdate ? 0 : -1"/>
			</a-radio-group>
			<a-button
				v-if="canUpdate"
				type="link"
				icon="close"
				tabindex="-1"
				:title="$t('checklist.clear.title')"
				:class="clearBtnClasses" @click="handleClearCheck"/>
		</div>
	</div>
</template>

<script>
import {Radio} from "ant-design-vue"
import _uniqueId from "lodash/uniqueId"

export default {
	components : {
		"a-radio-group" : Radio.Group , "a-radio" : Radio,
	} ,
	model : {
		prop : 'value',
		event : 'change'
	} ,
	props : {
		canUpdate : {
			type : Boolean,
			default : false,
		} ,
		value : {
			type : Boolean,
			default : undefined,
		} ,
		label : {
			type : String,
			default : '',
		} ,
	} ,
	computed : {
		radioName(){
			return this.canUpdate ? _uniqueId("serv_checklist_") : undefined
		} ,
		checkOptions() {
			return [
				{'value' : true} ,
				{'value' : false}
			]
		} ,
		clearBtnClasses() {
			return [
				'text-muted','check-clear-btn' ,
				{
					'check-clear-btn-active' : this.value !== undefined
				}
			]
		}
	} ,
	methods : {
		handleChange(event) {
			if (this.canUpdate)
				this.$emit('change',event.target.value)
		} ,
		handleClearCheck() {
			if (this.canUpdate)
				this.$emit('change',undefined)
		}
	}
}
</script>
